@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", system-ui;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.poppins-thin {
    font-family: "Poppins", system-ui;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", system-ui;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", system-ui;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", system-ui;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", system-ui;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", system-ui;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", system-ui;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", system-ui;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", system-ui;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", system-ui;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", system-ui;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", system-ui;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", system-ui;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", system-ui;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", system-ui;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", system-ui;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", system-ui;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", system-ui;
    font-weight: 900;
    font-style: italic;
}

:root {
    /*variables Colors*/
    --primary-color: #FFFFFF;
    --secondary-color: #000000;
    --tertiary-color: #FF8F00;
    --text-color: #555555;

    /*variables Text*/
    --mega-header: 4em;
    --mini-header: 3em;
    --header-text: 2em;
    --medium-text: 1.6em;
    --small-text: 1.2em;
    --p-text: 1em;
    --span-text: 0.8em;
    --link-span-text: 0.6em;
}

body,
html {
    overflow-x: hidden;
}

.navCtn {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    background: transparent;
    padding: 0px 5%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.navLogo {
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
}

.navLogo img {
    width: 180px;
    height: auto;
    object-fit: scale-down;
}

.navLogo h1 {
    color: var(--tertiary-color);
    font-size: var(--p-text);
    font-weight: 500;
}

.navLinks {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    gap: 2em;
}

.navLinks li {
    list-style: none;
}

.navLinks li a {
    text-decoration: none;
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 500;
}

.copyright {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: var(--secondary-color);
    padding: 20px 5%;
}

.copyright h1 {
    color: var(--tertiary-color);
    font-size: var(--span-text);
    font-weight: 500;
}

.footerLinks {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    gap: 1em;
    padding: 20px 10px;
}

.footerLinks li {
    list-style: none;
}

.footerLinks li a {
    text-decoration: none;
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 500;
}

.DashboardCtnView {
    display: none;
}

.BlogHeaderContent {
    width: 100%;
    height: 80vh;
    position: relative;
}

.BlogHeaderContent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BlogHeaderContentOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    justify-content: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding: 5%;
}

.BlogHeaderContentOverlay h2 {
    font-size: var(--header-text);
    color: var(--primary-color);
    font-weight: 500;
}

.BlogHeaderContentOverlay p {
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 300;
}

.BlogGridContainer {
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
}

.BlogGrid {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 1em;
    width: 100%;
    height: auto;
}

.BlogGridBox {
    justify-content: center;
    align-items: center;
    margin: auto;
    text-decoration: none;
    overflow: hidden;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.BlogGridBox img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.BlogGridBoxContent {
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.BlogGridBoxContent a {
    font-size: var(--small-text);
    font-weight: 500;
    color: var(--secondary-color);
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BlogGridBoxContent p {
    font-size: var(--span-text);
    font-weight: 300;
    color: var(--secondary-color);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 1024px) {

    .dashboardCtn {}

    .dashboardSiderbar {
        display: none !important;
    }

    .dashboardContent {
        display: none !important;
    }

    .dashboardFormCtn {
        overflow-y: scroll !important;
    }

}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 912px) {

    .dashboardCtn {
        display: none !important;
    }

    .DashboardCtnView {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
        height: 100vh;
        background-color: var(--secondary-color);
        padding: 10px;
    }

    .DashboardCtnView h2 {
        font-size: var(--small-text);
        color: var(--primary-color);
        font-weight: 500;
        text-align: center;
    }

}