@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

:root {
    /*variables Colors*/
    --primary-color: #FFFFFF;
    --secondary-color: #000000;
    --tertiary-color: #FF8F00;
    --text-color: #555555;

    /*variables Text*/
    --mega-header: 4em;
    --mini-header: 3em;
    --header-text: 2em;
    --medium-text: 1.6em;
    --small-text: 1.2em;
    --p-text: 1em;
    --span-text: 0.8em;
    --link-span-text: 0.6em;
    --icon-text: .8em;
}

.ctn {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    width: 100%;
    height: auto;
    padding: 5%;
}

.height {
    height: 100vh;
    overflow: hidden;
}

.gradient {
    padding: 20px;
    background: rgb(190, 122, 35);
    background: linear-gradient(0deg, rgba(190, 122, 35, 1) 21%, rgba(255, 143, 0, 1) 49%, rgba(171, 106, 20, 1) 73%, rgba(254, 254, 254, 1) 100%);
}

.form {
    width: 500px;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 10px;
}

.hover_background {
    position: absolute;
}

.formHeader {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 0;
}

.formHeader h2 {
    font-size: var(--medium-text);
    color: var(--primary-color);
    font-weight: 500;
}

.formHeader p {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 500;
}

.glassyBg {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
}

form {
    width: 100%;
    max-width: 650px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1em;
    padding: 10px;
    margin: 0 auto;
}

form input {
    width: 100%;
    height: auto;
    display: flex;
    padding: 15px 10px;
    border-radius: 5px;
    outline: none;
    color: var(--secondary-color);
    font-size: var(--span-text);
    font-weight: 400;
    border: 1px solid var(--primary-color);
}

form textarea {
    width: 100%;
    height: auto;
    display: flex;
    padding: 15px 10px;
    border-radius: 5px;
    outline: none;
    color: var(--secondary-color);
    font-size: var(--span-text);
    font-weight: 400;
    border: 1px solid var(--primary-color);
}

/* CKEditor Wrapper */
.ck-editor__editable {
    width: 100%;
    height: 200px;
    min-height: 200px;
    max-height: 200px;
    padding: 15px 10px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    color: var(--secondary-color);
    font-size: var(--span-text);
    font-weight: 400;
    outline: none;
    box-shadow: none;
}

/* CKEditor Toolbar */
.ck.ck-editor__top {
    border-radius: 5px;
    background-color: #f5f5f5;
    border-bottom: 1px solid var(--primary-color);
}

/* CKEditor Content Area */
.ck-editor__editable_inline {
    background-color: #fff;
}

/* CKEditor Focus State */
.ck-editor__editable:focus {
    border-color: var(--focus-color);
    box-shadow: 0 0 5px var(--focus-color);
}

/* Adjust CKEditor Toolbar buttons */
.ck.ck-toolbar__items {
    display: flex;
    justify-content: space-between;
}

.ck.ck-toolbar__items .ck-button {
    margin-right: 5px;
}

/* Scrollbar customization for content area (optional) */
.ck-editor__editable {
    overflow-y: auto;
}

form button {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 15px 10px;
    border-radius: 5px;
    outline: none;
    font-size: var(--span-text);
    font-weight: 500;
    color: var(--primary-color);
    background-color: var(--tertiary-color);
    border: 1px solid var(--tertiary-color);
}

.spanText {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    margin: auto;
    text-align: left;
    width: 80%;
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 500;
}

.spanTextNotification {
    text-decoration: none;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 500;
}

.clickLink {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    margin: auto;
    text-align: center;
    font-size: var(--link-span-text);
    color: var(--primary-color);
    font-weight: 500;
    background-color: var(--tertiary-color);
    padding: 10px;
    border-radius: 5px;
}

.flexCtn {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: .5em;
}

.dashboardCtn {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.dashboardSiderbar {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 20px 10px;
    height: auto;
    gap: .5em;
}

.dashboardSiderbar li {
    list-style: none;
}

.dashboardSiderbar li a {
    text-decoration: none;
    color: var(--primary-color);
    font-size: var(--span-text);
    font-weight: 500;
    width: 100%;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: var(--tertiary-color);
}

.dashboardContent {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    height: 100%;
    border-left: 1px solid var(--secondary-color);
}

.dashboardContentHeader {
    justify-content: center;
    align-items: flex-start;
    margin: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.dashboardContentHeader h2 {
    font-size: var(--medium-text);
    color: var(--secondary-color);
    font-weight: 700;
}

.dashboardContentHeader p {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: var(--span-text);
    color: var(--secondary-color);
    font-weight: 500;
}

.dashboardList {
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: .5em;
    padding: 10px;
}

.dashboardList::-webkit-scrollbar {
    display: none;
}

.box {
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    padding: 10px;
    height: auto;
    width: 100%;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2)
}

.box:nth-child(even) {
    background-color: rgb(219, 210, 157);
}

.boxImage {
    width: auto;
    height: auto;
    margin: 0 10px;
    display: none;
}

.boxImage img {
    width: 150px;
    height: auto;
    border-radius: 10px;
    object-fit: scale-down;
}

.boxContent {
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto 0;
    display: flex;
    flex-direction: column;
}

.boxContent h2 {
    font-size: var(--span-text);
    color: var(--secondary-color);
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.boxContent p {
    font-size: var(--span-text);
    color: var(--secondary-color);
    font-weight: 500;
    padding-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: none;
}

.boxContentDate {
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 20px 0;
    display: none;
}

.boxContentDate h3 {
    font-size: var(--span-text);
    color: var(--secondary-color);
    font-weight: 500;
}

.boxContentDate p {
    font-size: var(--span-text);
    color: var(--secondary-color);
    font-weight: 500;
    margin-left: auto;
}

.dashboardFormCtn {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: var(--secondary-color);
}

.dashboardFormCtnTitle {
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.dashboardFormCtnTitle h3 {
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 700;
}

.dashboardFormCtnTitle p {
    font-size: var(--span-text);
    color: var(--primary-color);
    font-weight: 500;
}

.formBackground {
    width: 100%;
    height: auto;
    display: flex;
    padding: 15px 10px;
    border-radius: 5px;
    outline: none;
    color: var(--secondary-color);
    background-color: whitesmoke;
    font-size: var(--span-text);
    font-weight: 400;
    border: 1px solid var(--primary-color);
}

.formBackgroundImg {
    width: 100%;
    height: auto;
    display: flex;
    padding: 12px 10px;
    border-radius: 5px;
    outline: none;
    color: var(--secondary-color);
    background-color: whitesmoke;
    font-size: var(--span-text);
    font-weight: 400;
    border: 1px solid var(--primary-color);
}

.formBackgroundTextarea {
    width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 10px;
    display: flex;
    padding: 15px 10px;
    border-radius: 5px;
    outline: none;
    font-weight: 400;
    font-size: var(--span-text);
    color: var(--secondary-color);
    background-color: whitesmoke;
    border: 1px solid var(--primary-color);
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

.btnFlexCtn {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: .5em;
    padding: 5px;
    border-left: 1px solid var(--secondary-color);
}

.iconFont {
    font-size: var(--icon-text);
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 5px;
    border-radius: 25px;
    display: flex;
    text-decoration: none;
    border: none;
    outline: none;
}

.HomeHeader {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--secondary-color);
    padding: 5%;
}

.HomeHeaderContent {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.HomeHeaderContent span {
    color: var(--primary-color);
    font-size: var(--span-text);
    font-weight: 300;
    background: rgba(100, 100, 100, .4);
    backdrop-filter: saturate(180%) blur(10px);
    padding: 10px;
    border-radius: 5px;
}

.HomeHeaderContent span text {
    color: var(--tertiary-color);
    font-weight: 700;
}

.HomeHeaderContent h1 {
    color: var(--tertiary-color);
    font-size: var(--header-text);
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}

.HomeHeaderContent p {
    color: var(--primary-color);
    font-size: var(--span-text);
    font-weight: 300;
}

.HomeHeaderFlex {
    justify-content: flex-start;
    align-items: center;
    margin: auto 0;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.HomeHeaderFlex a {
    width: auto;
    height: auto;
    display: flex;
    text-decoration: none;
}

.HomeHeaderFlex a img {
    width: 150px;
    height: auto;
    object-fit: scale-down;
    margin-top: 30px;
}

.HomeHeaderImg {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.HomeHeaderImg img {
    width: 250px;
    height: auto;
    object-fit: scale-down;
}

.AboutHeader {
    width: 100%;
    height: 80vh;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    background-color: var(--secondary-color);
    padding: 5%;
}

.AboutHeaderContent {
    width: 100%;
    max-width: 60%;
    height: auto;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.AboutHeaderContent span {
    color: var(--primary-color);
    font-size: var(--span-text);
    font-weight: 300;
    background: rgba(100, 100, 100, .4);
    backdrop-filter: saturate(180%) blur(10px);
    padding: 5px;
    border-radius: 5px;
}

.AboutHeaderContent span text {
    color: var(--tertiary-color);
    font-weight: 700;
}

.AboutHeaderContent h2 {
    color: var(--tertiary-color);
    font-size: var(--header-text);
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}

.AboutHeaderContent p {
    color: var(--primary-color);
    font-size: var(--span-text);
    font-weight: 300;
}

.AboutSection {
    width: 100%;
    height: auto;
    padding: 5%;
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.SectionHeader {
    text-align: center;
    padding: 10px;
}

.SectionHeader h3 {
    font-size: var(--header-text);
    font-weight: 500;
    color: var(--tertiary-color);
}

.SectionHeader p {
    font-size: var(--span-text);
    font-weight: 500;
    color: var(--secondary-color);
}

.SectionGrid {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, auto));
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    height: auto;
    padding: 20px 0;
}

.SectionGridContentBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.SectionGridContentBox span {
    font-size: var(--span-text);
    color: var(--tertiary-color);
    padding-right: 10px;
    font-weight: 700;
}

.SectionContent h4 {
    font-size: var(--span-text);
    font-weight: 500;
    color: var(--tertiary-color);
}

.SectionContent p {
    font-size: var(--span-text);
    font-weight: 500;
    color: var(--secondary-color);
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 600px) {

    .HomeHeader {
        flex-direction: column;
        height: auto;
    }

    .navCtn {
        position: relative;
        background-color: var(--secondary-color);
    }

    .HomeHeaderImg {
        justify-content: flex-end;
        padding: 30px 0;
    }

    .navLinks {
        display: none;
    }   

    .HomeHeaderContent h1 {
        font-size: var(--small-text);
    }

    .SectionGrid {
        justify-content: center;
        align-items: center;
        margin: auto;
        display: flex;
        flex-direction: column;
    }

    .AboutHeaderContent h2 {
        font-size: var(--small-text);
    }

    .AboutHeaderContent {
        max-width: 100%;
    }
    
    .form {
        width: 100%;
        padding: 10px;
    }

    .flexCtn {
        flex-direction: column;
    }

}